import React from "react"
import SEO from "../components/seo"
import logo from "../../src/images/logo.svg"
import { Link } from "gatsby"

const Impressum = () => {
  return (
    <div className="rechtliches">
      <SEO title="Impressum" />
      <div className="grid-container">
        <Link to="/">
          <img src={logo} alt="Blessing" className="rechtliches__logo" />
        </Link>
        <h1>Impressum</h1>
        <div className="section">
          <p>Samira Blessing </p>
          <p>Weddingsinger</p>
          <p>Bergstraße 19</p>
          <p>71157 Hildrizhausen</p>
          <p>
            Telefon: <a href="tel:01637409558">01637409558</a>
          </p>
          <p>
            E-Mail:{" "}
            <a href="mailto:samira-blessing@web.de">samira-blessing@web.de</a>
          </p>
        </div>
      </div>
    </div>
  )
}
export default Impressum
